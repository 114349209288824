import styled from "styled-components";
import { _CardIcon, _CardWrapper } from "../../atoms/Card/_Card";

export const _Tile = styled.div`
  text-align: left;

  ${_CardWrapper} {
    align-items: start;
  }

  ${_CardIcon} {
    margin-left: 0;
  }
`;

export const _TileTitle = styled.div`
  flex: 1 1 auto;
`;

export const _TileIcon = styled.div`
  ${_CardIcon} {
    justify-content: center;
  }
`;
