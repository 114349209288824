import { connect } from "react-redux";
import { RootState } from "../../../redux";
import CurrentUser from "./CurrentUser";
import { CurrentUserProps } from "./CurrentUser.types";

function mapStateToProps(
  state: RootState
): Pick<CurrentUserProps, "name" | "isLoading" | "isForgotten"> {
  return {
    name: state.userMeta.user?.fullName || "\u00A0",
    isForgotten: state.userMeta.user?.isForgotten || false,
    isLoading: state.userMeta.isLoading,
  };
}

export default connect(mapStateToProps)(CurrentUser);
