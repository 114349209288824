import { useMemo } from "react";
import Notification, {
  NotificationVariant,
} from "../components/atoms/Notification";
import { FormattedMessage } from "react-intl";
import { Icon } from "../components/molecules/Icon/Icon";
import { IconSize } from "../components/molecules/Icon/IconSize";
import Head from "next/head";
import { withRole } from "../config/withRole";
import Container from "../components/atoms/Container";
import CurrentUser from "../components/molecules/CurrentUser";
import ReportBetterNotification from "../components/molecules/Notifications/ReportBetterNotification";
import Layout from "../components/organisms/Layout";
import { Role } from "../contexts/Auth/Auth";
import withLabel, { getServerSideProps } from "../hoc/with-label";
import { useIntl } from "react-intl";
import { useGetUpcomingPaymentsQuery } from "../redux/apis/payments";
import { useGetAbsenceStatusQuery } from "../redux/apis/absence";
import { useLabel } from "../hooks/useLabel";
import { Labels } from "../types/Label";
import Tile from "../components/molecules/Tile";
import TileList from "../components/molecules/TileList";
import { CardVariant } from "../components/atoms/Card";
import Currency from "../components/atoms/Currency";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { useGTM } from "../hooks/useGTM";
import { GTMEvents } from "../config/gtm-events";
import { useRouter } from "next/router";

type HomeProps = {
  isExit?: boolean;
  isForgotten?: boolean;
  isReadOnly?: boolean;
};

const Home = ({ isExit, isReadOnly }: HomeProps) => {
  const { data: payment, isLoading: paymentIsLoading } =
    useGetUpcomingPaymentsQuery();

  const { data: absenceStatus } = useGetAbsenceStatusQuery();

  // Up to and including 2024-12-05
  const showSaintNicholasNotification = useMemo(() => {
    const today = new Date();
    const end = new Date("2024-12-06T00:00:00");

    return today <= end;
  }, []);

  // Up to and including 2024-12-09
  const showPaymentNotification = useMemo(() => {
    const today = new Date();
    const end = new Date("2024-12-10T00:00:00");

    return today <= end;
  }, []);

  // From 2024-12-10 up to and including 2025-12-10
  const showChristmasNotification = useMemo(() => {
    const today = new Date();
    const start = new Date("2024-12-10T00:00:00");
    const end = new Date("2025-01-11T00:00:00");

    return today >= start && today <= end;
  }, []);

  const saintNicholasNotification = useMemo(() => {
    return (
      showSaintNicholasNotification && (
        <Notification.Base variant={NotificationVariant.PRIMARY}>
          <Notification.Wrapper>
            <Notification.Icon left>
              <Icon name="InfoCircled" fill="white" size={IconSize.XS} />
            </Notification.Icon>
            <Notification.Content>
              <Notification.Text>
                <FormattedMessage id={"saintNicholasNotification"} />
              </Notification.Text>
            </Notification.Content>
          </Notification.Wrapper>
        </Notification.Base>
      )
    );
  }, [showSaintNicholasNotification]);

  const paymentNotification = useMemo(() => {
    return (
      showPaymentNotification && (
        <Notification.Base variant={NotificationVariant.PRIMARY}>
          <Notification.Wrapper>
            <Notification.Icon left>
              <Icon name="InfoCircled" fill="white" size={IconSize.XS} />
            </Notification.Icon>
            <Notification.Content>
              <Notification.Text>
                <FormattedMessage id={"paymentNotification"} />
              </Notification.Text>
            </Notification.Content>
          </Notification.Wrapper>
        </Notification.Base>
      )
    );
  }, [showPaymentNotification]);

  const christmasNotification = useMemo(() => {
    return (
      showChristmasNotification && (
        <Notification.Base variant={NotificationVariant.PRIMARY}>
          <Notification.Wrapper>
            <Notification.Icon left>
              <Icon name="InfoCircled" fill="white" size={IconSize.XS} />
            </Notification.Icon>
            <Notification.Content>
              <Notification.Text>
                <FormattedMessage id={"christmasNotification"} />
              </Notification.Text>
            </Notification.Content>
          </Notification.Wrapper>
        </Notification.Base>
      )
    );
  }, [showChristmasNotification]);

  // Create title (<FormattedMessage> cannot be in <Head>)
  const intl = useIntl();
  const title = intl.formatMessage(
    {
      id: "titleTemplate",
    },
    { title: intl.formatMessage({ id: "dashboard.title" }) }
  );

  const { trackEvent } = useGTM();

  const router = useRouter();

  return (
    <Layout>
      <Head>
        <title>{title}</title>
      </Head>
      <Container>
        <CurrentUser />
        {absenceStatus?.isAbsent && (
          <ReportBetterNotification pending={absenceStatus?.pending} />
        )}
        {paymentNotification}
        {saintNicholasNotification}
        {christmasNotification}
        <TileList>
          <Tile
            title={<Currency value={payment?.amount ?? 0} />}
            subtitle={
              <FormattedMessage id="dashboard.tiles.payments.subtitle" />
            }
            icon="Money"
            variant={CardVariant.DEFAULT}
            isLoading={paymentIsLoading}
            onClick={() => {
              trackEvent({
                event: GTMEvents.Dashboard,
                item: "payments",
              });
              router.push("/payments");
            }}
          />
          <Tile
            title={<FormattedMessage id="dashboard.tiles.payslips.title" />}
            subtitle={
              <FormattedMessage id="dashboard.tiles.payslips.subtitle" />
            }
            icon="Document"
            variant={CardVariant.DEFAULT}
            onClick={() => {
              trackEvent({
                event: GTMEvents.Dashboard,
                item: "payslips",
              });
              router.push("/payslips");
            }}
          />
          <Tile
            title={
              <FormattedMessage id="dashboard.tiles.vacationHours.title" />
            }
            subtitle={
              <FormattedMessage id="dashboard.tiles.vacationHours.subtitle" />
            }
            icon="SuitcaseHoliday"
            variant={CardVariant.DEFAULT}
            onClick={() => {
              trackEvent({
                event: GTMEvents.Dashboard,
                item: "vacation-hours",
              });
              router.push("/vacation-hours");
            }}
          />
          <Tile
            title={<FormattedMessage id="dashboard.tiles.documents.title" />}
            subtitle={
              <FormattedMessage id="dashboard.tiles.documents.subtitle" />
            }
            icon="Documents"
            variant={CardVariant.DEFAULT}
            onClick={() => {
              trackEvent({
                event: GTMEvents.Dashboard,
                item: "documents",
              });
              router.push("/profile/documents");
            }}
          />
          <Tile
            title={<FormattedMessage id="dashboard.tiles.wageTax.title" />}
            icon="WageTax"
            variant={CardVariant.DEFAULT}
            onClick={() => {
              trackEvent({
                event: GTMEvents.Dashboard,
                item: "wage-tax",
              });
              router.push("/profile/information/financial");
            }}
          />

          {!isExit && !isReadOnly && (
            <Tile
              title={<FormattedMessage id="dashboard.tiles.exit.title" />}
              icon="Leave"
              variant={CardVariant.DEFAULT}
              onClick={() => {
                trackEvent({
                  event: GTMEvents.Dashboard,
                  item: "report-exit",
                });
                router.push("/profile");
              }}
            />
          )}

          {isExit && !isReadOnly && (
            <Tile
              title={<FormattedMessage id="dashboard.tiles.forget.title" />}
              icon="Leave"
              variant={CardVariant.DEFAULT}
              onClick={() => {
                trackEvent({
                  event: GTMEvents.Dashboard,
                  item: "report-forgotten",
                });
                router.push("/profile");
              }}
            />
          )}

          <Tile
            title={<FormattedMessage id="dashboard.tiles.profile.title" />}
            subtitle={
              <FormattedMessage id="dashboard.tiles.profile.subtitle" />
            }
            icon="Person"
            variant={CardVariant.DEFAULT}
            onClick={() => {
              trackEvent({
                event: GTMEvents.Dashboard,
                item: "profile",
              });
              router.push("/profile");
            }}
          />
          <Tile
            title={<FormattedMessage id="dashboard.tiles.help.title" />}
            subtitle={<FormattedMessage id="dashboard.tiles.help.subtitle" />}
            icon="Question"
            variant={CardVariant.DEFAULT}
            onClick={() => {
              trackEvent({
                event: GTMEvents.Dashboard,
                item: "help",
              });
              router.push("/help");
            }}
          />
        </TileList>
      </Container>
    </Layout>
  );
};

function mapStateToProps(state: RootState) {
  return {
    isExit: state.userMeta.user?.exit,
    isForgotten: state.userMeta.user?.isForgotten,
    isReadOnly: state.userMeta.isReadOnly,
  };
}

export default withLabel(
  withRole(connect(mapStateToProps)(Home), [
    Role.EMPLOYEE,
    Role.IMPERSONATING_EMPLOYEE,
  ])
);

export { getServerSideProps };
