import React, { useEffect } from "react";
import { GetServerSideProps } from "next";
import { getOriginFromReq } from "../helpers/get-origin-from-req";
import { useLabel } from "../hooks/useLabel";
import { HOST_LABEL } from "../constants/label";

function withLabel<P extends object>(Component: React.ComponentType<P>) {
  return (
    props: P & {
      __label: string;
      __origin: string;
    }
  ) => {
    const { setLabelName } = useLabel();

    useEffect(() => {
      setLabelName(props.__label);
    }, [props.__label, setLabelName]);

    return (
      <>
        <Component {...props} />
      </>
    );
  };
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const __origin = getOriginFromReq(context.req);
  const __label = HOST_LABEL[__origin as keyof typeof HOST_LABEL];

  return {
    props: {
      __origin,
      __label,
    },
  };
};

export default withLabel;
