import { FormattedNumber } from "react-intl";
import { ReactNode } from "react";

export interface CurrencyProps {
  value: number | bigint;
}

export default function Currency({ value, ...props }: CurrencyProps) {
  return (
    <FormattedNumber
      value={value}
      style="currency"
      currency="EUR"
      children={(msg?: ReactNode) => <>{msg}</>}
      {...props}
    />
  );
}
