import React, { FunctionComponent } from "react";
import { LinkOrButtonType } from "../../atoms/LinkOrButton/LinkOrButton";
import { _Tile, _TileTitle, _TileIcon } from "./_Tile";
import { IconSize } from "../Icon/IconSize";
import { IconName } from "../Icon/IconName";
import Card, { CardVariant } from "../../atoms/Card";
import { Icon } from "../Icon/Icon";
import Skeleton from "react-loading-skeleton";

export type TileProps = {
  icon: IconName;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  href?: string;
  action?: () => void;
  variant?: CardVariant;
  isLoading?: boolean;
} & Partial<LinkOrButtonType>;

const Tile: FunctionComponent<TileProps> = ({
  title,
  subtitle,
  icon,
  href,
  action,
  variant,
  isLoading = false,
  ...props
}) => {
  const hasAction = action || href;
  if (isLoading) {
    return (
      <Card.Base variant={variant}>
        <Card.Content>
          <_Tile {...props}>
            {icon && (
              <_TileIcon>
                <Card.Icon>
                  <Skeleton count={1} width={36} height={36} circle />
                </Card.Icon>
              </_TileIcon>
            )}
            <Card.Wrapper>
              <_TileTitle>
                <Card.Title>
                  <Skeleton count={1} width={100} height={21} />
                </Card.Title>
                <Card.Text>
                  <Skeleton count={1} width={60} height={17} />
                </Card.Text>
              </_TileTitle>
            </Card.Wrapper>
          </_Tile>
        </Card.Content>
      </Card.Base>
    );
  }

  return (
    <Card.Base href={href} action={action} variant={variant}>
      <Card.Content>
        <_Tile {...props}>
          {icon && (
            <_TileIcon>
              <Card.Icon>
                <Icon size={IconSize.LG} name={icon} />
              </Card.Icon>
            </_TileIcon>
          )}
          <Card.Wrapper>
            <_TileTitle>
              <Card.Title>{title}</Card.Title>
              <Card.Text>{subtitle}</Card.Text>
            </_TileTitle>

            {hasAction && (
              <Card.Icon right>
                <Icon size={IconSize.SM} name="ArrowRight" />
              </Card.Icon>
            )}
          </Card.Wrapper>
        </_Tile>
      </Card.Content>
    </Card.Base>
  );
};

export default Tile;
