import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import Avatar from "../Avatar";
import {
  _CurrentUserText,
  _CurrentUserTextSkeleton,
  _CurrentUserWrapper,
} from "./_CurrentUser";
import { CurrentUserProps } from "./CurrentUser.types";
import { useTheme } from "../../../hooks/useTheme";

export function CurrentUser({ name, isLoading }: CurrentUserProps) {
  const { theme } = useTheme();
  return (
    <SkeletonTheme
      baseColor={theme.colors.SKELETON_BASE}
      highlightColor={theme.colors.SKELETON_HIGHLIGHT}
    >
      <_CurrentUserWrapper>
        <Avatar />
        {isLoading ? (
          <_CurrentUserTextSkeleton count={1} width={180} height={19} />
        ) : (
          <_CurrentUserText>{name}</_CurrentUserText>
        )}
      </_CurrentUserWrapper>
    </SkeletonTheme>
  );
}

export default CurrentUser;
