import React, { FunctionComponent, ReactElement } from "react";
import { _TileList, _TileListItem } from "./_TileList";

type TileListProps = {
  children: any;
};

const TileList: FunctionComponent<TileListProps> = ({ children, ...props }) => {
  return (
    <_TileList {...props}>
      {children &&
        React.Children.map(children, (child: ReactElement) => {
          if (React.isValidElement(child)) {
            return <_TileListItem>{React.cloneElement(child)}</_TileListItem>;
          }
        })}
    </_TileList>
  );
};

export default TileList;
