import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import Text from "../../atoms/Text";

export const _CurrentUserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const _CurrentUserText = styled(Text)`
  display: block;
  margin-top: 5px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 1.1rem;
`;

export const _AvatarSkeleton = styled(Skeleton)`
  margin-bottom: 10px;
`;

export const _CurrentUserTextSkeleton = styled(Skeleton)`
  margin-top: 5px;
  margin-bottom: 11px;
`;
