import styled from "styled-components";
import { _Card, _CardInner } from "../../atoms/Card/_Card";

export const _TileList = styled.ul`
  list-style: none none outside;
  padding: 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - 10px), 1fr));
`;

export const _TileListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-items: flex-end;

  > * {
    display: flex;
    flex: 1 1 auto;
  }

  ${_Card} {
    margin-bottom: 0 !important;
  }
`;
